.loginbox {
  background-color: #fff;
  margin: 0 auto;
  max-width: 42rem;
  padding: var(--gap);
  box-sizing: border-box;
}
.loginbox__form {
  margin: 0;
}
.loginbox__submit {
  margin: 0;
}
.loginbox__errmsg {
  padding: var(--gap05);
  margin: 0 0 var(--gap05);
  background-color: lightcoral;
  color: #fff;
}
.loginbox__errmsg > p {
  margin: 0;
}

@media (min-width: 44em) {
  .loginbox {
    max-width: 56rem;
    padding: var(--gap2);
  }
  .loginbox__errmsg {
    padding: var(--gap);
    margin: 0 0 var(--gap);
  }
}

@media (min-width: 76em) {
  .loginbox {
    max-width: 80rem;
  }
  .loginbox__form {
    display: flex;
    align-items: flex-end;
    gap: var(--gap);
  }
  .loginbox__form > label {
    flex: 1;
    margin: 0;
  }
}
